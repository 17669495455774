import React, { useState, useRef } from "react";

function Card() {

    // Arrays declaration
    const UPPERCASE_LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const LOWERCASE_LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const SYMBOLS = ['!', '#', '$', '%', '&', '(', ')', '*', '+']

    // Fisher–Yates shuffle
    function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
    }

    // Generate password function
    function generatePassword(upperlower, numbers, symbols, length) {
                            
        let passwordArray = [];
        let arraysPool = [LOWERCASE_LETTERS];
        let password = "";
        let passwordLength = length;

        let upperLowerCB = upperlower;
        let numbersCB = numbers;
        let symbolsCB = symbols;

        // Checks options
        if (upperLowerCB) {
            arraysPool.push(UPPERCASE_LETTERS);
        }
        if (numbersCB) {
            arraysPool.push(NUMBERS);
        }
        if (symbolsCB) {
            arraysPool.push(SYMBOLS);
        }

        // Checks if severity is covered
        let severityPool = [];
        for (let i = 0; i < arraysPool.length; i++) {
            let is_present = passwordArray.some(r => arraysPool[i].indexOf(r) >= 0);
            severityPool.push(is_present);
        }

        // Generates password array
        while (severityPool.includes(false)) {
            passwordArray = [];
            for (let i = 0; i < passwordLength; i++) {
            let pool = arraysPool[Math.floor(Math.random() * arraysPool.length)];
            let element = pool[Math.floor(Math.random() * pool.length)];
            passwordArray.push(element);
            }
            severityPool = [];
            for (let i = 0; i < arraysPool.length; i++) {
            let is_present = passwordArray.some(r => arraysPool[i].indexOf(r) >= 0);
            severityPool.push(is_present);
            }
        }

        let shuffledPasswordArray = shuffleArray(passwordArray);

        // Creates password string from shuffled password array
        for (let i = 0; i < shuffledPasswordArray.length; i++) {
            password += shuffledPasswordArray[i];
        }
        
        return password;
    }

    // Copyright date
    function getYear() {
        const d = new Date();
        return d.getFullYear();
    }
    
    const [passwordText, setPassword] = useState(generatePassword(true, true, true, 14));
    const [sliderText, setSlider] = useState(14);

    const upperLowerRef = useRef(true);
    const numbersRef = useRef(true);
    const symbolsRef = useRef(true);
    const lengthRef = useRef(14);

    // Prepares array for checking severity requirements
    const severityArray = [upperLowerRef.current.checked, numbersRef.current.checked, symbolsRef.current.checked].map(function(element) {
        if(element === undefined || element === true) {
            return true;
        } else {
            return false;
        }
    });

    var noStrong = 0;

    severityArray.forEach(element => {
        if (element === false) {
            noStrong += 1;
        }
        return noStrong;
    });

    // Events handling functions

    function handleSliderChange(event) {
        setSlider(event.target.value);
        setPassword(generatePassword(upperLowerRef.current.checked, numbersRef.current.checked, symbolsRef.current.checked, event.target.value));
    }
    
    function handleClick() {
        setPassword(generatePassword(upperLowerRef.current.checked, numbersRef.current.checked, symbolsRef.current.checked, lengthRef.current.value));
    }

    function handleChangeUpper(event) {
        setPassword(generatePassword(upperLowerRef.current.checked, numbersRef.current.checked, symbolsRef.current.checked, lengthRef.current.value));
    }

    function handleChangeNumbers(event) {
        setPassword(generatePassword(upperLowerRef.current.checked, numbersRef.current.checked, symbolsRef.current.checked, lengthRef.current.value));
    }

    function handleChangeSymbols(event) {
        setPassword(generatePassword(upperLowerRef.current.checked, numbersRef.current.checked, symbolsRef.current.checked, lengthRef.current.value));
    }

    return (
        <div className="card">
            <div className="card-body">
            <h3 className="card-title">Strong Password Generator</h3>
            <div className="pwd-display" id="password">{passwordText}</div>
                    <label htmlFor="passwordlength" className="form-label">Password length:&nbsp;</label>
                    <output className="pwd-length-text" id="pwdlength">{sliderText}</output>
                    <input type="range" min="8" max="20" className="form-range" ref={lengthRef} id="passwordlength" defaultValue={14} onChange={handleSliderChange} />
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="upperlowercb" ref={upperLowerRef} id="upperlowercb" onChange={handleChangeUpper} defaultChecked />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    Uppercase letters
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="numberscb" ref={numbersRef} id="numberscb" onChange={handleChangeNumbers} defaultChecked />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                    Numbers
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="symbolscb" ref={symbolsRef} id="symbolscb" onChange={handleChangeSymbols} defaultChecked />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                    Symbols (!#$%&*+)
                    </label>
                </div>
                { noStrong > 1 ? <p className="warning" id="warning">Always consider using at least two of the above options for a strong password</p> : <p className="empty-warning"></p> }
                <button type="button" className="btn btn-primary mt-4 d-block mx-auto" onClick={handleClick}>Generate Password</button>
                <div className="copyright" id="copyright">&copy;<span id="date">{getYear()}</span> <a href="https://www.dgtworks.com">dgtWorks.com</a></div>
            </div>
        </div>
    );
    
}

export default Card;